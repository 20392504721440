import React from "react"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import PortableText from "@sanity/block-content-to-react"
import Img from "gatsby-image"

import {
  PostHero,
  PostTitle,
  PostContent,
  PostContentLeft,
  PostContentLeftBody,
  PostContentRight,
  PostNextTitle,
} from "../templates/post/post-styles.js"

import {
  BlogGridPost,
  BlogGridPostLeft,
  BlogPostTitle,
  BlogPostContent,
  BlogPostLink,
  BlogPostDate,
  BlogGridPostRight,
} from "../components/blog/blog-grid-styles"

const CovidPage = ({ data }) => {
  const content = data.sanityPricing._rawContent
  const featuredImg = data.sanityPricing.featuredImage.image.asset.fluid

  return (
    <>
      <SEO title="Pricing" />
      <PostHero>
        <PostTitle>Pricing</PostTitle>
      </PostHero>
      <PostContent>
        <PostContentLeft>
          <PostContentLeftBody>
            <PortableText
              blocks={content}
              serializers={{
                types: {
                  ptImage: ({ node }) => (
                    <img src={node.image.asset.url} alt={node.alt} />
                  ),
                },
              }}
            />
          </PostContentLeftBody>
          {data.next && (
            <>
              <PostNextTitle>Next Post</PostNextTitle>
              <BlogGridPost>
                <BlogGridPostLeft>
                  <BlogPostTitle>{data.next.title}</BlogPostTitle>
                  <BlogPostContent>
                    <PortableText
                      blocks={data.next._rawContent}
                      serializers={{
                        types: {
                          ptImage: ({ node }) => (
                            <img src={node.image.asset.url} alt={node.alt} />
                          ),
                        },
                      }}
                    />
                  </BlogPostContent>
                  <BlogPostLink to={"/blog/" + data.next.slug.current + "/"}>
                    Read More
                  </BlogPostLink>
                  <BlogPostDate>
                    {new Date(data.next.publishedAt)
                      .toDateString()
                      .split(" ")
                      .slice(1)
                      .join(" ")}
                  </BlogPostDate>
                </BlogGridPostLeft>
                <BlogGridPostRight>
                  <Img fluid={data.next.featuredImage.image.asset.fluid} />
                </BlogGridPostRight>
              </BlogGridPost>
            </>
          )}
        </PostContentLeft>
        <PostContentRight>
          <Img fluid={featuredImg} />
        </PostContentRight>
      </PostContent>
    </>
  )
}

export const query = graphql`
  query pricingData {
    sanityPricing {
      featuredImage {
        image {
          asset {
            fluid(maxWidth: 960) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      _rawContent(resolveReferences: { maxDepth: 10 })
    }
  }
`

export default CovidPage
